<template>
  <page-view>
    <div class="balance_wrapper">
      <!-- 统计 -->
      <div class="data_statistics">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <div class="statistics_item">
              <span class="item_title">账户余额</span>
              <div class="total">
                <span class="totalmoney red">{{ account.balance ? parseFloat(account.balance).toLocaleString() : 0 }}</span>
                元
              </div>
            </div>
            <span class="actionlink" @click="showPayModal">马上充值</span>
          </a-col>
          <a-col :md="6" :sm="24">
            <div class="statistics_item">
              <span class="item_title">可提现余额</span>
              <div class="total">
                <span class="totalmoney primary_blue">{{
                  account.balanceWithdrawabl ? parseFloat(account.balanceWithdrawabl).toLocaleString() : 0
                }}</span>
                元
              </div>
            </div>
            <span v-if="account.openWithdrawal" @click="editWithdraw(true)" class="actionlink">立即提现</span>
          </a-col>
          <a-col :md="6" :sm="24">
            <div class="statistics_item">
              <span class="item_title">提现中余额</span>
              <div class="total">
                <span class="totalmoney">{{ account.balanceCash ? parseFloat(account.balanceCash).toLocaleString() : 0 }}</span>
                元
              </div>
            </div>
          </a-col>
          <a-col :md="6" :sm="24">
            <div class="statistics_item last_item">
              <span class="item_title">总提现金额</span>
              <div class="total">
                <span class="totalmoney">{{ account.balanceCashAll ? parseFloat(account.balanceCashAll).toLocaleString() : 0 }}</span>
                元
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <!-- 表格 -->
      <div class="balance_table_wrapper">
        <a-tabs :activeKey="activeTabKey" @change="handleTabChange">
          <a-tab-pane :tab="array.name" v-for="array in typeArray" :key="array.value"></a-tab-pane>
          <a-input slot="tabBarExtraContent" class="queryInput" placeholder="查询流水号" v-model.trim="queryParam.no"></a-input>
          <a-button slot="tabBarExtraContent" class="queryBtn" type="primary" @click="$refs.table.refresh(false)">查询</a-button>
          <a-button slot="tabBarExtraContent" @click="queryReset">重置</a-button>
        </a-tabs>

        <s-table style="margin:0 24px;padding-bottom:24px" ref="table" rowKey="id" :columns="columns" :data="dataSource">
          <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
          <template slot="no" slot-scope="text, record">
            <a-tooltip placement="right" overlayClassName="tooltip_custom">
              <!-- <template slot="title">
                <span>点击复制</span>
              </template> -->

              <span class="uid_fat">
              <!-- <span class="uid_fat" @mouseover="handleMouseover(record)" @mouseout="handleMouseout(record)"> -->
                {{ text.substring(0, 4) + '……' + text.substring(text.split('').length - 4) }}
                <a @click="copyInfo(record.no)">点击复制</a>
                <!-- <span class="uid_child"  v-show="record.show">{{ text }}</span> -->
              </span>
            </a-tooltip>
          </template>
        </s-table>
      </div>

      <payModal ref="paymodal" @checkDetails="checkDetails" />
      <!-- 提现弹框 -->
      <a-modal title="资金提现" :width="560" :visible="withdraw.show" :maskClosable="false" @cancel="editWithdraw(false)" :footer="null">
        <!-- 没有支付宝 -->
        <a-form v-if="!account.alipayAccount || !account.alipayName">
          <a-form-item label="到账账号" :labelCol="{ span: 6 }" :wrapperCol="{ span: 8 }">
            <a-input type="text" v-model="withdraw.alipayAccount" class="alipay_input" autocomplete="false" placeholder="请输入支付宝账号"></a-input>
          </a-form-item>
          <a-form-item label="真实姓名" :labelCol="{ span: 6 }" :wrapperCol="{ span: 8 }">
            <a-input v-model="withdraw.alipayName" type="text" class="alipay_input" autocomplete="false" placeholder="请输入支付宝真实姓名"></a-input>
          </a-form-item>
          <a-form-item label="提现金额" :labelCol="{ span: 6 }" :wrapperCol="{ span: 8 }">
            <a-input-number
              class="alipay_input input_number"
              autocomplete="false"
              v-model="withdraw.balanceCash"
              :placeholder="`可提现金额￥${account.balanceWithdrawabl}元`"
              :precision="2"
            ></a-input-number>
            <!-- <span class="withdrawabl" @click="withdrawAll">全部</span> -->
          </a-form-item>
        </a-form>
        <!-- 有支付宝 -->
        <a-form v-if="account.alipayAccount && account.alipayName">
          <a-form-item label="到账账号" :labelCol="{ span: 6 }" :wrapperCol="{ span: 15 }">
            <div class="account_box">
              支付宝{{ account.alipayAccount.substring(0, 3) + '****' + account.alipayAccount.substring(7) }}（{{
                account.alipayName.substring(0, 1) + '*' + account.alipayName.substring(2)
              }}）
              <span class="withdraw_now" @click="withdrawNowBtn">点击修改</span>
            </div>
          </a-form-item>
          <a-form-item label="提现金额" :labelCol="{ span: 6 }" :wrapperCol="{ span: 8 }">
            <a-input-number
              class="alipay_input input_number"
              autocomplete="false"
              v-model="withdraw.balanceCash"
              :placeholder="`可提现金额￥${account.balanceWithdrawabl}元`"
              :precision="2"
              :min="0"
            ></a-input-number>
            <span class="withdrawabl" @click="withdrawAll">全部</span>
          </a-form-item>
        </a-form>
        <a-button style="margin-left:129px" type="primary" htmlType="submit" @click.stop.prevent="withdrawOk">立即提现</a-button>
      </a-modal>
    </div>
  </page-view>
</template>

<script>
import { STable } from '@/components';
import { rechargePay, accountGet, accountWithdraw } from '@api';
import { Modal } from 'ant-design-vue';
import { PageView } from '@/layouts';
import { billList, accountUpdate } from '@api';
import { formatDate, jsonToArray, billType } from '@/utils/util';
import payModal from './payModal';
export default {
  components: {
    PageView,
    STable,
    payModal
  },
  data() {
    return {
      withdraw: { show: false },
      pay: { show: false },
      account: {},
      activeTabKey: 'all',
      typeArray: jsonToArray(billType),
      queryParam: {},
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '流水号',
          dataIndex: 'no',
          scopedSlots: { customRender: 'no' }
        },
        {
          title: '金额',
          dataIndex: 'amount',
          customRender: (text) => text + '元'
        },
        {
          title: '类型',
          dataIndex: 'type',
          customRender: (text) => billType[text]
        },
        {
          title: '描述',
          dataIndex: 'comment'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: formatDate
        }
      ],
      dataSource: (parameter) => {
        return billList(Object.assign(parameter, this.queryParam)).then((result) => {
          result.data.list.forEach((item) => (item.show = false));
          return result.data;
        });
      }
    };
  },
  mounted() {
    //tab栏加上全部
    let obj = {
      name: '全部',
      value: 'all'
    };
    this.typeArray.unshift(obj);
  },
  created() {
    this.accountInfoGet();
  },
  methods: {
    handleMouseover(record) {
      record.show = true;
    },
    handleMouseout(record) {
      record.show = false;
    },
    // 复制流水号
    copyInfo(record) {
      // textarea可以使用\n   input只有单行
      const oInput = document.createElement('textarea');
      oInput.value = record;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand('Copy');
      oInput.style.display = 'none';
      document.body.removeChild(oInput);
      this.$message.success('复制成功');
    },
    //查看充值记录
    checkDetails() {
      this.activeTabKey = 'RECHARGE';
      this.queryParam.type = 'RECHARGE';
      this.$refs.table.refresh(false);
      //查询明细
      accountGet().then((res) => {
        this.account = res.data;
      });
    },
    // 全部提现
    withdrawAll() {
      this.withdraw.balanceCash = this.account.balanceWithdrawabl;
    },
    withdrawNowBtn() {
      this.$router.push('/account/base');
    },
    // tab切换
    handleTabChange(e) {
      this.activeTabKey = e;
      if (e == 'all') {
        this.queryParam = {};
      } else {
        this.queryParam.type = e;
      }

      this.$refs.table.refresh(true);
    },
    // 重置查询
    queryReset() {
      this.queryParam = {};
      this.activeTabKey = 'all';
      this.$refs.table.refresh(false);
    },
    accountInfoGet() {
      accountGet().then((res) => {
        this.account = res.data;
        // console.log(this.account);
      });
    },
    editPay(flag) {
      this.pay = { show: flag, amount: 1 };
    },
    showPayModal() {
      // 当前没有id先刷新
      if (!this.account.uid) {
        return this.$message.info('请先刷新页面再充值');
      }
      this.$refs.paymodal.showModal();
    },
    editWithdraw(flag) {
      if (flag) {
        let account = JSON.parse(JSON.stringify(this.account));
        account.balanceCash = this.account.balanceWithdrawabl;
        account.show = flag;
        this.withdraw = account;
      } else {
        this.withdraw.show = flag;
      }
    },
    handleOk() {
      const pay = this.pay;
      pay.payway = 'ALIPAY';
      const opener = window.open();
      const that = this;
      rechargePay(pay).then((result) => {
        if (result.success) {
          // start alipayForm
          window.localStorage.setItem('alipay', result.data);
          opener.location.href = '/alipay.html';
          /*
          const div = document.createElement('div');
          div.innerHTML = result.data;
          opener.document.body.appendChild(div);
          opener.document.forms[0].submit();*/
          // end alipayForm
          Modal.confirm({
            title: '信息',
            content: '支付中...',
            onOk() {
              that.$refs.table.refresh(false);
              that.accountInfoGet();
              opener.close();
              that.editPay(false);
            },
            onCancel() {
              opener.close();
              that.editPay(false);
            },
            okText: '完成支付',
            cancelText: '遇到问题'
          });
        } else {
          opener.close();
          this.$message.error('支付失败');
        }
      });
    },
    withdrawOk() {
      const withdraw = this.withdraw;
      // 没有支付宝的情况
      if (!this.account.alipayAccount || !this.account.alipayName) {
        const data = {
          alipayAccount: withdraw.alipayAccount.trim(),
          alipayName: withdraw.alipayName.trim()
        };
        //绑定支付宝
        accountUpdate(data).then((res) => {
          if (res.success) {
            //提现
            this.handleAccountWithdraw(withdraw);
          } else {
            this.$message.error(res.msg);
          }
          return;
        });
      }
      this.handleAccountWithdraw(withdraw);
    },
    handleAccountWithdraw(data) {
      accountWithdraw(data).then((result) => {
        if (result.success) {
          //查询
          this.accountInfoGet();
          //关闭
          this.editWithdraw(false);
          //提示
          this.$message.success('提现成功');
        } else {
          this.$message.error(result.msg);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.balance_wrapper {
  .data_statistics {
    box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
    background-color: #fff;
    padding: 32px 40px;
    margin-bottom: 24px;
    .statistics_item {
      border-right: 1px solid rgba(240, 240, 240, 1);

      margin-bottom: 12px;
      .item_title {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-bottom: 8px;
      }
      .total {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        .totalmoney {
          font-size: 30px;
          font-family: Roboto;
          color: rgba(0, 0, 0, 0.85);
        }
        .red {
          color: rgba(249, 50, 68, 1);
        }
        .primary_blue {
          color: #1890ff;
        }
      }
    }
    .actionlink {
      cursor: pointer;
      font-size: 14px;
      color: #1890ff;
    }
    .last_item {
      border-right: 0;
    }
  }
  .balance_table_wrapper {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
    .queryInput {
      width: 240px;
      height: 32px;
      margin-right: 12px;
    }
    .queryBtn {
      margin-right: 12px;
    }
  }
}
.top5 {
  margin-top: 8px;
}
.alipay_input {
  width: 320px;
  height: 32px;
}
.input_number {
  position: relative;
}
.withdrawabl {
  color: #1890ff;
  position: absolute;
  right: 16px;
  top: -10px;
  font-size: 14px;
  cursor: pointer;
  padding: 0 10px;
}
.account_box {
  display: flex;
  justify-content: space-between;
}
.withdraw_now {
  color: #1890ff;
  padding: 0 10px;

  cursor: pointer;
}

.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
//tab栏
/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 16px 0 16px;
  padding: 16px 12px;
}
/deep/ .ant-tabs {
  margin-right: 24px;
}
/deep/ .ant-tabs-extra-content {
  line-height: 53px;
}
</style>
