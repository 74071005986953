<template>
  <a-modal title="余额充值" :width="720" :visible="visible" :maskClosable="false" @cancel="closeModal" :footer="null">
    <div class="has_nopay" v-if="!haspay">
      <div class="charge_account">
        <span>充值账户：</span>
        <span>{{ user.mobile }}</span>
      </div>
      <div class="charge_money">
        <span>充值金额：</span>
        <ul>
          <li :class="amount == item ? 'active' : ''" v-for="(item, index) in amountArr" @click="changeChargeAmount(item)" :key="index">
            {{ item }}元
            <img v-if="amount == item" src="@/assets/bottomright.png" alt class="dagou" />
          </li>
        </ul>
      </div>
      <div class="charge_custom">
        <a-input-number
          class="custom_input"
          placeholder="请输入充值金额，最低1元"
          v-model="amountinput"
          :precision="2"
          :min="1"
          :max="10000"
        ></a-input-number>

        <span class="custom_btn" @click="customAmount">确定</span>
      </div>
      <div class="pay_way_choose">
        <span>支付方式：</span>
        <div class="way">
          <div class="payway" @click="changePayWay('ALIPAY_QRCODE')" :class="payWay == 'ALIPAY_QRCODE' ? 'active' : ''">
            <!-- <img class="img-balance" src="@/assets/icon／alipay.png" alt="支付宝" /> -->
            <a-icon type="alipay" class="alipay_icon pay_icon" />
            <span>支付宝付款</span>

            <img v-if="payWay == 'ALIPAY_QRCODE'" src="@/assets/bottomright.png" alt class="dagou" />
          </div>
          <!-- <div
            class="payway"
            @click="changePayWay('WECHAT_QRCODE')"
            :class="payWay == 'WECHAT_QRCODE' ? 'active' : ''"
          >
            <a-icon type="wechat" class="wechat_icon pay_icon" />
            <span>微信付款</span>

            <img v-if="payWay == 'WECHAT_QRCODE'" src="@/assets/bottomright.png" alt class="dagou" />
          </div>-->
        </div>
      </div>
      <div class="payway_info">
        <div class="qrcode">
          <canvas id="canvas"></canvas>
          <div class="qrcode_tip" v-if="payWay == 'ALIPAY_QRCODE'">
            打开支付宝
            <br />扫描二维码支付
          </div>
          <div class="qrcode_tip" v-else>
            打开微信
            <br />扫描二维码支付
          </div>
        </div>
      </div>
    </div>
    <div class="haspay" v-else>
      <a-icon type="check-circle" theme="filled" :style="{ color: '#52c41a', fontSize: '70px' }" />
      <div class="pay_success">充值成功</div>
      <div class="order_view">可在余额管理中查看</div>
      <div class="goback_btn">
        <div class="goback_btn_one" @click="checkDetails">查看详情</div>
        <div class="goback_btn_two" @click="closeModal">关闭页面</div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { rechargePay, rechargeList } from '@/api';
import QRCode from 'qrcode';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      visible: false,
      payWay: 'ALIPAY_QRCODE',
      amount: 20,
      amountArr: [20, 100, 500, 2000],
      amountinput: '',
      haspay: false,
      timeOutCount: 0, // 定时器执行次数
      user: this.userInfo(),
      timer: null
    };
  },
  created() {},
  methods: {
    ...mapGetters(['userInfo']),
    checkDetails() {
      this.visible = false;
      this.$emit('checkDetails');
    },
    closeModal() {
      this.amountinput = '';
      this.haspay ? this.checkDetails() : (this.visible = false);
    },
    checkPayStatus(no) {
      this.timeOutCount++;
      // 3s * 60  180s
      if (this.timeOutCount > 60) {
        this.$message.success('3分钟未支付，取消支付状态检测，请稍后重试');
        if (this.visible) {
          this.visible = false;
        }
      } else {
        console.log(this.timeOutCount, no);
        rechargeList({ no }).then((res) => {
          let status = res.data.list[0].status;
          if (status == 'SUCCESS') {
            this.$message.success('支付成功');
            this.haspay = true;
          } else {
            if (this.timer) {
              clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
              this.checkPayStatus(no);
            }, 3000);
          }
        });
      }
    },
    customAmount() {
      if (!this.amountinput) {
        return this.$message.error('请输入大于1的充值金额');
      }
      this.amount = Number(this.amountinput);
      if ([500, 1000, 2000, 5000].indexOf(this.amount) == -1) {
        let input = document.querySelector('.custom_input');
        input.classList.add('input_active');
      }

      this.handleChargePay();
    },
    handleChargePay() {
      const params = {
        amount: this.amount,
        payway: this.payWay
      };
      this.timeOutCount = 0;
      rechargePay(params).then((res) => {
        if (res.success) {
          var canvas = document.getElementById('canvas');
          QRCode.toCanvas(canvas, res.data.qrCode);
          this.checkPayStatus(res.data.no);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changePayWay(way) {
      this.payWay = way;
    },
    changeChargeAmount(num) {
      this.amount = Number(num);
      this.amountinput = '';
      document.querySelector('.custom_input').classList.remove('input_active');
      this.handleChargePay();
    },

    showModal() {
      this.visible = true;
      this.timer && clearTimeout(this.timer);
      this.amount = 20;
      this.haspay = false;
      this.handleChargePay();
      this.$nextTick(() => {
        document.querySelector('.custom_input').classList.remove('input_active');
      });
    }
  }
};
</script>

<style lang="less" scoped>
.has_nopay {
  padding: 0;
  margin: 0;
  padding-left: 56px;
  font-family: PingFang SC;
  color: rgba(0, 0, 0, 0.85);
  .dagou {
    position: absolute;
    right: -1px;
    bottom: -1px;
  }

  .charge_account {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }

  .charge_money {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    ul {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;

      li {
        cursor: pointer;
        width: 80px;
        height: 32px;
        border: 1px solid rgba(217, 217, 217, 1);
        margin-right: 16px;
        border-radius: 4px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .active {
        border: 1px solid #1890ff;
        position: relative;
      }
    }
  }

  .charge_custom {
    margin-left: 70px;
    margin-bottom: 24px;
    .custom_input {
      width: 194px;
      height: 32px;
      position: relative;
    }
    .input_active {
      border: 1px solid #1890ff !important;
      position: relative;
    }

    span.custom_btn {
      color: #1890ff;
      cursor: pointer;
      padding: 0 8px;
    }
  }

  .pay_way_choose {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .way {
      display: flex;

      .payway {
        cursor: pointer;
        width: 120px;
        height: 32px;
        border: 1px solid rgba(217, 217, 217, 1);
        border-radius: 4px;
        display: flex;
        padding-left: 8px;
        align-items: center;
        margin-right: 16px;

        span {
          margin-left: 6px;
        }
        .pay_icon {
          color: #fff;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
        .alipay_icon {
          background-color: #198cff;
        }
        .wechat_icon {
          background-color: #4dac3d;
        }
      }
      .active {
        border: 1px solid #1890ff;
        position: relative;
      }
    }
  }

  .payway_info {
    margin-left: 56px;

    .qrcode {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      #canvas {
        width: 140px;
        height: 140px;
        margin-right: 24px;
      }
    }
  }
}
.haspay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 36px;
  .pay_success {
    margin-top: 29px;
    font-size: 24px;
    margin-bottom: 8px;
  }
  .order_view {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 24px;
  }
  .goback_btn {
    display: flex;
    font-size: 14px;
    margin-bottom: 32px;
    .goback_btn_one {
      cursor: pointer;
      margin-right: 8px;
      width: 116px;
      height: 32px;
      color: #1e95f3;
      border: 1px solid rgba(30, 149, 243, 1);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .goback_btn_two {
      cursor: pointer;
      width: 88px;
      height: 32px;
      background: rgba(30, 149, 243, 1);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }
}
</style>
